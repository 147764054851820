import { Vector2 } from '../../model/waveEngineModel';
import { waveCommands } from '../commands';
import { getDevicePixelRatio } from '../service/utils';

export type EntityAbsolutePosition = {
  radius: number;
} & Vector2;

const getEntityAbsolutePosition = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  entityId: string
): EntityAbsolutePosition => {
  if (canvasRef && canvasRef.current) {
    const screenEntity = waveCommands.state.getEntityScreenPosition(entityId);
    const { top, left } = canvasRef.current.getBoundingClientRect();
    const devicePixelRatio = getDevicePixelRatio();
    return {
      x: left + screenEntity.position.x / devicePixelRatio,
      y: top + screenEntity.position.y / devicePixelRatio,
      radius: screenEntity.radius / devicePixelRatio
    };
  } else {
    throw Error('No canvas assigned to get the entity screen position');
  }
};

export { getEntityAbsolutePosition };
