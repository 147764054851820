import * as canvas from './canvas';
import * as core from './core';
import * as model from './model';
import * as utils from './utils';

const waveService = {
  core,
  canvas,
  model,
  utils
};

export { waveService };
export * from './constants';
