import { useEffect, useRef } from 'react';
import {
  InteractionEvent,
  WaveClickEventPayload,
  WaveSelectionChangedEventPayload
} from '../../model/waveEngineModel';

export type WaveEvents = {
  onClick?: (event: WaveClickEventPayload) => void;
  onSelectionChanged?: (event: WaveSelectionChangedEventPayload) => void;
  onViewChanged?: () => void;
};

const useWaveEvents = (
  isWebAssemblyLoaded: boolean,
  { onClick, onSelectionChanged, onViewChanged }: WaveEvents
): void => {
  const onClickRef = useRef<typeof onClick>();
  const onSelectionChangedRef = useRef<typeof onSelectionChanged>();
  const onViewChangedRef = useRef<typeof onViewChanged>();

  useEffect(() => {
    onClickRef.current = onClick;
  }, [onClick]);

  useEffect(() => {
    onSelectionChangedRef.current = onSelectionChanged;
  }, [onSelectionChanged]);

  useEffect(() => {
    onViewChangedRef.current = onViewChanged;
  }, [onViewChanged]);

  useEffect(() => {
    const onEvent = (event: InteractionEvent): void => {
      if (event.type === 'Click') {
        onClickRef.current?.(event.payload);
      } else if (event.type === 'SelectionChanged') {
        onSelectionChangedRef.current?.(event.payload);
      } else if (event.type === 'ViewChanged') {
        onViewChangedRef.current?.();
      }
    };

    let subscription: ((event: InteractionEvent) => void) | null = null;
    if (
      isWebAssemblyLoaded &&
      (onClickRef.current ||
        onSelectionChangedRef.current ||
        onViewChangedRef.current)
    ) {
      subscription = window.WaveEngine.subscribe(onEvent);
    }

    return () => {
      if (subscription) {
        window.WaveEngine?.unsubscribe(subscription);
      }
    };
  }, [isWebAssemblyLoaded]);
};

export { useWaveEvents };
