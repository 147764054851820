import * as data from './data';
import * as forms from './forms';
import * as screen from './screen';

const waveTools = {
  screen,
  forms,
  data
};

export { waveTools };
export * from './screen';
