import { MeshProperties } from '../../model/waveEngineModel';
import { executeCommand } from './utils';

type ShapeType = 'Cube' | 'Cylinder';

type NewEntityResponse = {
  id: string;
  name: string;
};

const createShape = (
  type: ShapeType,
  meshProperties?: Partial<MeshProperties>,
  entityProperties?: { [key: string]: string }
): string => {
  const result = JSON.parse(
    executeCommand<string>('NewEntity', {
      Type: type,
      Name: meshProperties?.name,
      Color: meshProperties?.color,
      IsRenderable: meshProperties?.isRenderable,
      IsInteractable: meshProperties?.isRenderable,
      Scale: meshProperties?.scale,
      IsPushpinVisible: meshProperties?.isPushpinVisible,
      PushpinId: meshProperties?.pushpinId,
      Properties: entityProperties
    })
  ) as NewEntityResponse;

  return result.id;
};

export { createShape };
