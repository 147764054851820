const buildEntityPropertiesFromForm = (form: {
  [key: string]: string;
}): { [key: string]: string } => {
  const properties: { [key: string]: string } = {};

  for (const key of Object.keys(form)) {
    properties[key] = form[key];
  }

  return properties;
};

const buildFormFromEntityProperies = <T>(properties: {
  [key: string]: string;
}): T => {
  const form = {} as T;
  for (const key of Object.keys(properties)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form as any)[key] = properties[key];
  }

  return form;
};

export { buildEntityPropertiesFromForm, buildFormFromEntityProperies };
