import { useRef } from 'react';

export type WaveAction = {
  action: string;
  payload?: any;
};

type SubscriptionsRef = Record<string, ((payload?: any) => void) | undefined>;

type UseWaveCommunication<TActionName, TWaveAction extends WaveAction> = {
  sendAction: (action: TWaveAction) => void;
  subscribe: <T>(
    action: TActionName,
    callback: (payload: T) => void
  ) => () => void;
  handleMessage: (waveAction: TWaveAction) => void;
};

type UseWaveCommunicationProps<TWaveAction extends WaveAction> = {
  sendAction: (action: TWaveAction) => void;
};

const useWaveCommunication = <
  TActionName extends string,
  TWaveAction extends WaveAction
>({
  sendAction
}: UseWaveCommunicationProps<TWaveAction>): UseWaveCommunication<
  TActionName,
  TWaveAction
> => {
  const subscriptionsRef = useRef<SubscriptionsRef>({});

  const handleMessage = (waveAction: TWaveAction) => {
    const { action } = waveAction;
    if ('payload' in waveAction) {
      subscriptionsRef.current[action]?.(waveAction.payload);
    } else {
      subscriptionsRef.current[action]?.();
    }
  };

  const subscribe = <T>(
    action: TActionName,
    callback: (payload: T) => void
  ): (() => void) => {
    subscriptionsRef.current[action] = callback;

    return () => {
      subscriptionsRef.current[action] = undefined;
    };
  };

  return { sendAction, subscribe, handleMessage };
};

export { useWaveCommunication };
