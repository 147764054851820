const assemblyName = 'SmartOccupancy.CADViewer.Web';

export const invoke = <T = void>(
  className: string,
  methodName: string | Array<string>,
  ...args: unknown[]
): T =>
  window.BINDING.call_static_method(
    `[${assemblyName}] ${assemblyName}.${className}:${methodName}`,
    args
  ) as T;
