import create from 'zustand';

export type WaveEngineState = {
  isWaveLoaded: boolean;
  isWebAssemblyLoaded: boolean;
  isDXFLoaded: boolean;
  setWaveLoaded: (loaded: boolean) => void;
  setWebAssemblyLoaded: (loaded: boolean) => void;
  setDXFLoaded: (loaded: boolean) => void;
};

const useStore = create<WaveEngineState>((set) => ({
  isWaveLoaded: false,
  isWebAssemblyLoaded: false,
  isDXFLoaded: false,
  setWaveLoaded: (value) =>
    set(() => ({
      isWaveLoaded: value,
      isDXFLoaded: false
    })),
  setWebAssemblyLoaded: (value) =>
    set(() => ({
      isWebAssemblyLoaded: value,
      isWaveLoaded: false,
      isDXFLoaded: false
    })),
  setDXFLoaded: (value) => set(() => ({ isDXFLoaded: value }))
}));

export { useStore as useWaveEngineStore };
