import { loadDXF, loadDXFMetadata } from './model';

const devicePixelRatio = window.devicePixelRatio || 1;

const getDevicePixelRatio: () => number = () => devicePixelRatio;

const writeToFs = (
  fileName: string,
  buffer: Int8Array,
  dropDirectory = '/tmp/DXFViewer'
) => {
  const FS = window.FS;
  try {
    FS.stat(dropDirectory);
  } catch (err) {
    FS.mkdir(dropDirectory);
  }

  const destination = `${dropDirectory}/${fileName}`;
  const stream = window.FS.open(destination, 'w+');
  FS.write(stream, buffer, 0, buffer.length, 0);
  FS.close(stream);

  return destination;
};

const loadDXFModel = async (
  model: string | Int8Array,
  file: string = 'Model.dxf'
) => {
  let buffer: Int8Array;

  if (typeof model === 'string') {
    const response = await fetch(model);
    const targetBuffer = await response.arrayBuffer();
    buffer = new Int8Array(targetBuffer);
  } else {
    buffer = model;
  }

  const modelPath = await loadFileToFS(buffer, file);
  loadDXF(modelPath);
};

const loadDXFMetadataBuffer = async (
  data: Int8Array | null,
  file: string = 'Model.json'
) => {
  const dataPath = data ? await loadFileToFS(data, file) : '';
  loadDXFMetadata(dataPath);
};

const loadDXFData = async (data: string, file: string = 'Model.json') => {
  const response = await fetch(data);
  const buffer = await response.arrayBuffer();
  const dataPath = await loadFileToFS(new Int8Array(buffer), file);
  loadDXFMetadata(dataPath);
};

const loadFileToFS = async (
  buffer: Int8Array,
  targetFile: string
): Promise<string> => {
  const modelPath = writeToFs(targetFile, buffer);
  return modelPath;
};

export {
  writeToFs,
  loadDXFModel,
  loadDXFMetadataBuffer,
  loadDXFData,
  getDevicePixelRatio
};
