import { WAVE_CLASS_NAME } from './constants';
import { invoke } from './core';

const loadDXF = (path: string): void =>
  invoke(WAVE_CLASS_NAME, 'LoadDXF', path);

const loadDXFMetadata = (path: string): void =>
  invoke(WAVE_CLASS_NAME, 'LoadData', path);

export { loadDXF, loadDXFMetadata };
