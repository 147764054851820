import { WAVE_CLASS_NAME } from '../service';
import { invoke } from '../service/core';

const executeCommand = <T = void | string>(
  commandName: string,
  args?: {}
): T => {
  const command = {
    Command: commandName,
    ...args
  };

  return invoke(WAVE_CLASS_NAME, 'ExecuteCommand', command) as T;
};

export { executeCommand };
