import { InteractionEvent } from '../model/waveEngineModel';
import { useWaveEngineStore } from '../stores/waveEngineStore';
import { waveService, WAVE_CANVAS_ID } from './service';

const addScript = (path: string, defer = false): Promise<void> =>
  new Promise((resolve) => {
    if (!document.querySelector(`script[src='${path}']`)) {
      const script = document.createElement('script');
      script.setAttribute('src', path);
      if (defer) {
        script.setAttribute('defer', 'defer');
      }
      document.body.appendChild(script);
      script.onload = () => {
        resolve();
      };
    } else {
      resolve();
    }
  });

const loadWaveScripts = async (baseUrl: string): Promise<void> => {
  await Promise.all([
    addScript(`${baseUrl}/mono-config.js`),
    addScript(`${baseUrl}/runtime.js`)
  ]);
  addScript(`${baseUrl}/content.js`, true);
  addScript(`${baseUrl}/dotnet.js`, true);
};

const initializeWaveEngine = async (baseUrl: string): Promise<void> => {
  await loadWaveScripts(baseUrl);

  window.Module['locateFile'] = (base: string) => `${baseUrl}/${base}`;

  window.Module['setProgress'] = (loadedBytes: number, totalBytes: number) => {
    // eslint-disable-next-line no-console
    console.log(`Loaded ${loadedBytes} of ${totalBytes}`);
  };

  const subscribers = new Array<(event: InteractionEvent) => void>();

  window.WaveEngine = {
    onEvent: (event: InteractionEvent) => {
      for (const subscriber of subscribers) {
        subscriber(event);
      }
    },
    subscribe: (
      callback: (event: InteractionEvent) => void
    ): ((event: InteractionEvent) => void) => {
      subscribers.push(callback);
      return callback;
    },
    unsubscribe: (subscription: (event: InteractionEvent) => void): void => {
      const index = subscribers.indexOf(subscription);
      subscribers.splice(index, 1);
    }
  };

  window.App = {
    init: () => {
      useWaveEngineStore.getState().setWebAssemblyLoaded(true);
      // eslint-disable-next-line no-console
      console.log('Wasm initialized');
    },
    waveInit: () => {
      useWaveEngineStore.getState().setWaveLoaded(true);
      // eslint-disable-next-line no-console
      console.log('Wave engined initialized');
    }
  };
};

const initializeCanvas = (): void => {
  const canvas = document.getElementById(WAVE_CANVAS_ID) as HTMLCanvasElement;

  if (!canvas) {
    alert('Initialization failed: WebGL canvas element not found.');
  }

  window.Module.canvas = canvas;

  waveService.canvas.waveInit();
  waveService.canvas.newWaveCanvas(WAVE_CANVAS_ID);
  waveService.canvas.updateCanvasSize(WAVE_CANVAS_ID);
};

const initializeDXFModel = async (model: string | Int8Array): Promise<void> => {
  useWaveEngineStore.getState().setDXFLoaded(false);
  await waveService.utils.loadDXFModel(model);
  setTimeout(() => useWaveEngineStore.getState().setDXFLoaded(true));
};

const destroyWaveCanvas = (): void => {
  waveService.canvas.destroyWaveCanvas(WAVE_CANVAS_ID);
  useWaveEngineStore.getState().setWaveLoaded(false);
};

export {
  initializeWaveEngine,
  initializeCanvas,
  initializeDXFModel,
  destroyWaveCanvas
};
