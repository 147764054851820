import {
  CameraState,
  ProjectionMode,
  Vector3
} from '../../model/waveEngineModel';
import { executeCommand } from './utils';

const vectorZero = { x: 0.0, y: 0.0, z: 0.0 } as Vector3;

const getCameraState = (): CameraState => {
  const result = JSON.parse(
    executeCommand<string>('GetCameraState')
  ) as CameraState;
  return result;
};

const enableOrthographicCamera = (): void =>
  executeCommand('SetCameraState', { Projection: ProjectionMode.Orthographic });

const enablePerspectiveCamera = (rotation?: Vector3): void =>
  executeCommand('SetCameraState', {
    Projection: ProjectionMode.Perspective,
    Rotation: rotation ?? vectorZero
  });

const setCameraRotation = (rotation: Vector3): void =>
  executeCommand('SetCameraState', { Rotation: rotation });

const zoomInCamera = (): void => executeCommand('ZoomIn');
const zoomOutCamera = (): void => executeCommand('ZoomOut');

const resetCamera = (): void => executeCommand('ResetCamera');

export {
  getCameraState,
  enableOrthographicCamera,
  enablePerspectiveCamera,
  resetCamera,
  setCameraRotation,
  zoomInCamera,
  zoomOutCamera
};
