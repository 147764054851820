import { WAVE_CANVAS_ID, WAVE_CLASS_NAME } from './constants';
import { invoke } from './core';

const newWaveCanvas = (canvasId: string): void => {
  invoke(WAVE_CLASS_NAME, 'NewCanvas', canvasId);
};

const waveInit = (): void => {
  invoke(WAVE_CLASS_NAME, 'Main', undefined);
};

const destroyWaveCanvas = (canvasId: string = WAVE_CANVAS_ID): void => {
  invoke(WAVE_CLASS_NAME, 'DestroyWaveCanvas', canvasId);
  window.Module.canvas = undefined;
};

const setBackgroundColor = (color: string): void => {
  const command = {
    Command: 'SetCameraState',
    BackgroundColor: color
  };
  invoke(WAVE_CLASS_NAME, 'ExecuteCommand', command);
};

const updateCanvasSize = (id: string): void =>
  invoke(WAVE_CLASS_NAME, 'UpdateCanvasSize', id);

export {
  newWaveCanvas,
  waveInit,
  destroyWaveCanvas,
  setBackgroundColor,
  updateCanvasSize
};
