import {
  Entity,
  EntityScreenPosition,
  MeshProperties,
  SceneState,
  WaveSelectedEntity
} from '../../model/waveEngineModel';
import { WAVE_CLASS_NAME } from '../service';
import { invoke } from '../service/core';
import { executeCommand } from './utils';

const getState = (): SceneState => {
  const sceneState = JSON.parse(
    executeCommand<string>('SaveState')
  ) as SceneState;

  for (const entity of sceneState.entities) {
    for (const prop of Object.keys(entity.properties)) {
      if (prop.indexOf('__mono') !== -1) delete entity.properties[prop];
    }
  }

  return sceneState;
};

const getEntity = (id: string): Entity | undefined =>
  getState().entities.find((e) => e.id === id);

const getEntityScreenPosition = (id: string): EntityScreenPosition =>
  JSON.parse(
    executeCommand<string>('GetEntityScreenPosition', { Id: id })
  ) as EntityScreenPosition;

const getSelectedEntities = (): WaveSelectedEntity[] =>
  JSON.parse(
    executeCommand<string>('GetSelectedEntities')
  ) as WaveSelectedEntity[];

const setSelection = (...ids: string[]): void =>
  executeCommand<void>('SetSelection', {
    Selection: ids
  });

const setEntityProperties = (
  id: string,
  properties: { [key: string]: string }
): Entity => {
  const entity = executeCommand<string>('SetEntityProperties', {
    Id: id,
    Properties: properties
  });
  return JSON.parse(entity) as Entity;
};

const setPartialEntityProperties = (
  id: string,
  properties: Partial<{ [key: string]: string }>
): Entity => {
  const currentEntityProps = getEntity(id)?.properties;
  const entity = executeCommand<string>('SetEntityProperties', {
    Id: id,
    Properties: { ...currentEntityProps, ...properties }
  });
  return JSON.parse(entity) as Entity;
};

const duplicateShape = (): void => executeCommand('Duplicate');
const undo = (): void => executeCommand('Undo');
const redo = (): void => executeCommand('Redo');
const removeSelectedEntities = (): void =>
  executeCommand('RemoveSelectedEntities');

const removeEntity = (id: string): void =>
  executeCommand('RemoveEntity', {
    Id: id
  });

const setReadOnly = (readOnly: boolean): void =>
  invoke<void>(WAVE_CLASS_NAME, 'IsReadOnly', readOnly);

const setEnableElevate = (enable: boolean): void =>
  invoke<void>(WAVE_CLASS_NAME, 'SetEnableElevate', enable);

const setEnableScale = (enable: boolean): void =>
  invoke<void>(WAVE_CLASS_NAME, 'SetEnableScale', enable);

const setEnableMove = (enable: boolean): void =>
  invoke<void>(WAVE_CLASS_NAME, 'SetEnableMove', enable);

const setEnableRotation = (enable: boolean): void =>
  invoke<void>(WAVE_CLASS_NAME, 'SetEnableRotation', enable);

const setMeshProperties = (
  id: string,
  properties: Partial<MeshProperties>
): Entity => {
  const entity = executeCommand<string>('SetMeshProperties', {
    Id: id,
    Name: properties.name,
    Color: properties.color,
    IsInteractable: properties.isInteractable,
    IsRenderable: properties.isRenderable,
    IsPushpinVisible: properties.isPushpinVisible,
    PushpinId: properties.pushpinId
  });
  return JSON.parse(entity) as Entity;
};

export {
  duplicateShape,
  undo,
  redo,
  removeSelectedEntities,
  removeEntity,
  getState,
  getEntity,
  getEntityScreenPosition,
  getSelectedEntities,
  setSelection,
  setEntityProperties,
  setPartialEntityProperties,
  setReadOnly,
  setEnableElevate,
  setEnableScale,
  setEnableMove,
  setEnableRotation,
  setMeshProperties
};
