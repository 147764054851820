import { StyleState } from '../../model/waveEngineModel';
import { executeCommand } from './utils';

const setStyle = (style: Partial<StyleState>): void => {
  executeCommand<string>('SetStyle', {
    OverridedMapColor: style.overridedMapColor,
    ManipulatorColor: style.manipulatorColor,
    SecondaryManipulatorColor: style.secondaryManipulatorColor,
    SelectionOverlayColor: style.selectionOverlayColor
  });
};

export { setStyle };
