import { useUpdateEffect } from '../hooks/useUpdateEffect';
import { useWaveEngineStore } from '../stores/waveEngineStore';
import React, { memo, ReactElement, useEffect, useMemo, useRef } from 'react';
import { waveService, WAVE_CANVAS_ID } from '../services/service';
import {
  destroyWaveCanvas,
  initializeCanvas,
  initializeDXFModel
} from '../services/waveInitialization';
import { waveCommands } from '../services/commands';
import { waveTools } from '../services/tools';
import {
  ManipulatorsConfiguration,
  useWaveManipulators
} from '../hooks/wave-canvas/useWaveManipulators';
import { useWaveEvents, WaveEvents } from '../hooks/wave-canvas/useWaveEvents';
import {
  StyleConfiguration,
  useWaveStyles
} from '../hooks/wave-canvas/useWaveStyles';
import { SceneState } from '../model/waveEngineModel';

const calculateSizeWithPixelRatio = (size: number): number =>
  size * waveService.utils.getDevicePixelRatio();

type WaveCanvasProps = {
  height: number;
  width: number;
  isReadonly?: boolean;
  dxfModel?: string | Int8Array;
  metadataModel?: SceneState | null;
  style?: StyleConfiguration;
  manipulators?: ManipulatorsConfiguration;
  canvasRef?: React.Ref<HTMLCanvasElement>;
} & WaveEvents;

const WaveCanvas = memo(
  ({
    height,
    width,
    dxfModel,
    isReadonly,
    manipulators,
    style,
    metadataModel = null,
    canvasRef,
    ...events
  }: WaveCanvasProps): ReactElement => {
    const isWebAssemblyLoaded = useWaveEngineStore(
      (s) => s.isWebAssemblyLoaded
    );
    const isWaveLoaded = useWaveEngineStore((s) => s.isWaveLoaded);

    const isWaveLoadedRef = useRef<boolean>(isWaveLoaded);

    useWaveStyles(isWaveLoaded, style);
    useWaveEvents(isWebAssemblyLoaded, events);
    useWaveManipulators(isWaveLoaded, manipulators);

    useEffect(() => {
      isWaveLoadedRef.current = isWaveLoaded;
    }, [isWaveLoaded]);

    const size = useMemo(
      () => ({
        height: height,
        width: width,
        pixelRatioAwareHeight: calculateSizeWithPixelRatio(height),
        pixelRatioAwareWidth: calculateSizeWithPixelRatio(width)
      }),
      [height, width]
    );

    useEffect(() => {
      if (isWebAssemblyLoaded) {
        initializeCanvas();
      }
    }, [isWebAssemblyLoaded]);

    useEffect(() => {
      if (isWaveLoaded && dxfModel) {
        initializeDXFModel(dxfModel);
      }
    }, [isWaveLoaded, dxfModel]);

    useEffect(() => {
      return () => {
        if (isWaveLoadedRef.current) {
          destroyWaveCanvas();
        }
      };
    }, []);

    useEffect(() => {
      if (isWaveLoaded) {
        const metadata = waveTools.data.objectToInt8Array(metadataModel);
        waveService.utils.loadDXFMetadataBuffer(metadata);
      }
    }, [isWaveLoaded, metadataModel]);

    useEffect(() => {
      if (isWaveLoaded && isReadonly !== undefined) {
        waveCommands.state.setReadOnly(isReadonly);
      }
    }, [isWaveLoaded, isReadonly]);

    useUpdateEffect(() => {
      if (isWebAssemblyLoaded) {
        waveService.canvas.updateCanvasSize(WAVE_CANVAS_ID);
      }
    }, [size]);

    return (
      <>
        {isWebAssemblyLoaded ? (
          <div
            style={{
              position: 'absolute',
              height: '100%'
            }}>
            <canvas
              ref={canvasRef || null}
              id={WAVE_CANVAS_ID}
              width={size.pixelRatioAwareWidth}
              height={size.pixelRatioAwareHeight}
              style={{
                height: `${size.height}px`,
                width: `${size.width}px`
              }}
              onContextMenu={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
              }}
            />
          </div>
        ) : null}
      </>
    );
  }
);

export { WaveCanvas };
