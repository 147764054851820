const objectToInt8Array = <T>(object: T): Int8Array | null => {
  if (object == null) return null;

  const value = JSON.stringify(object);
  const uint8array = new TextEncoder().encode(value);
  const array = new Int8Array(uint8array);
  return array;
};

export { objectToInt8Array };
