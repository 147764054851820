import * as camera from './camera';
import * as shape from './shape';
import * as state from './state';
import * as utils from './utils';
import * as style from './style';

const waveCommands = {
  camera,
  shape,
  state,
  utils,
  style
};

export { waveCommands };
