export enum ProjectionMode {
  Perspective = 0,
  Orthographic
}

export type CameraState = {
  backgroundColor: string;
  position: Vector3;
  projectionMode: ProjectionMode;
  projectionWidth: number;
  rotation: Vector3;
};

export type SceneState = {
  camera?: CameraState;
  entities: Array<Entity>;
};

export type StyleState = {
  overridedMapColor?: string;
  manipulatorColor?: string;
  secondaryManipulatorColor?: string;
  selectionOverlayColor?: string;
};

export type Entity = {
  id: string;
  name: string;
  color: string;
  isInteractable: boolean;
  isRenderable: boolean;
  position: Vector3;
  properties: { [key: string]: string };
  shapeType: string;
  scale: Vector3;
  isPushpinVisible?: boolean;
  pushpinId?: number;
};

export type MeshProperties = Pick<
  Entity,
  | 'name'
  | 'color'
  | 'isRenderable'
  | 'isInteractable'
  | 'scale'
  | 'isPushpinVisible'
  | 'pushpinId'
>;

export type WaveClickEventPayload = {
  id: string;
  name: string;
};

export type WaveSelectionChangedEventPayload = string[];

export type InteractionEvent =
  | {
      type: 'SelectionChanged';
      payload: WaveSelectionChangedEventPayload;
    }
  | {
      type: 'Click';
      payload: WaveClickEventPayload;
    }
  | { type: 'ViewChanged' };

export interface EntityScreenPosition {
  position: Vector2;
  radius: number;
}

export interface WaveSelectedEntity {
  id: string;
  name: string;
}

export interface WaveEntityProperty {
  name: string;
  value: string;
}

export type Vector2 = { x: number; y: number };

export type Vector3 = { x: number; y: number; z: number };
