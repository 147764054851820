import { useEffect } from 'react';
import { waveCommands } from '../../services/commands';

export type ManipulatorsConfiguration = {
  enableElevation?: boolean;
  enableScale?: boolean;
  enableRotate?: boolean;
  enableMove?: boolean;
};

const useWaveManipulators = (
  isWaveLoaded: boolean,
  config?: ManipulatorsConfiguration
): void => {
  const { enableElevation, enableMove, enableRotate, enableScale } =
    config || {};

  useEffect(() => {
    if (isWaveLoaded && enableElevation !== undefined) {
      waveCommands.state.setEnableElevate(enableElevation);
    }
  }, [isWaveLoaded, enableElevation]);

  useEffect(() => {
    if (isWaveLoaded && enableMove !== undefined) {
      waveCommands.state.setEnableMove(enableMove);
    }
  }, [isWaveLoaded, enableMove]);

  useEffect(() => {
    if (isWaveLoaded && enableRotate !== undefined) {
      waveCommands.state.setEnableRotation(enableRotate);
    }
  }, [isWaveLoaded, enableRotate]);

  useEffect(() => {
    if (isWaveLoaded && enableScale !== undefined) {
      waveCommands.state.setEnableScale(enableScale);
    }
  }, [isWaveLoaded, enableScale]);
};

export { useWaveManipulators };
