import { useEffect } from 'react';
import { StyleState } from '../../model/waveEngineModel';
import { waveCommands } from '../../services';
import { waveService } from '../../services/service';

export type StyleConfiguration = {
  backgroundColor?: string;
} & StyleState;

const useWaveStyles = (isWaveLoaded: boolean, style?: StyleConfiguration) => {
  const {
    backgroundColor,
    manipulatorColor,
    overridedMapColor,
    secondaryManipulatorColor,
    selectionOverlayColor
  } = style || {};

  useEffect(() => {
    if (isWaveLoaded && backgroundColor) {
      waveService.canvas.setBackgroundColor(backgroundColor);
    }
  }, [isWaveLoaded, backgroundColor]);

  useEffect(() => {
    if (
      isWaveLoaded &&
      (manipulatorColor ||
        overridedMapColor ||
        secondaryManipulatorColor ||
        selectionOverlayColor)
    ) {
      waveCommands.style.setStyle({
        manipulatorColor,
        overridedMapColor,
        secondaryManipulatorColor,
        selectionOverlayColor
      });
    }
  }, [
    isWaveLoaded,
    manipulatorColor,
    overridedMapColor,
    secondaryManipulatorColor,
    selectionOverlayColor
  ]);
};

export { useWaveStyles };
